
import PptEditor from '@/components/ppt-editor/PptEditor.vue'
import { useRoute, useRouter } from 'vue-router'
import { createVNode, onMounted, ref } from 'vue'
import { eventCenter, event } from '@evideo/frontend-utils'
import { getOfficialCoursewareInfo } from '../service'
import config from '@/config'
import { message, Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import * as service from '../service'
import { shareModeEnum } from '@/utils/dataMap'

export default {
  components: { PptEditor },
  beforeRouteLeave: (to, from, next) => {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })

    setTimeout(() => {
      next()
    }, 0)
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const id:string = (route.params.id as string)
    const fileInfo = ref<any>({})

    const pptEditor = ref()

    const fileName = ref('')

    const loading = ref(false)

    const canCopy = ref(false)

    const formData = ref('official')

    const url = ref(null)

    const handleGoBack = () => {
      router.push('/resource/courseware-square')
    }

    // 本地增加下载量
    const handleUpdateDownloadNum = () => {
      if (fileInfo.value.popularity) {
        if (fileInfo.value.popularity.download_num) {
          fileInfo.value.popularity.download_num++
        } else {
          fileInfo.value.popularity.download_num = 1
        }
      } else {
        fileInfo.value.popularity = {
          download_num: 1
        }
      }
    }

    const handleCopy = async () => {
      try {
        loading.value = true
        const params = { official_courseware_id: id }
        service.addDownloadLog(id)
        await service.copyOfficialCourseware(params)
        handleUpdateDownloadNum()
        message.success('添加到我的课件成功')
        loading.value = false
      } catch (error) {
        console.log(error)
        message.error('添加到我的课件失败，请重试')
        loading.value = false
      }
    }

    const handleShowWarning = () => {
      Modal.confirm({
        title: '是否确认将课件添加到我的课件中',
        icon: createVNode(ExclamationCircleOutlined),
        closable: true,
        onOk: handleCopy,
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }

    onMounted(async () => {
      const data = await getOfficialCoursewareInfo(id)
      url.value = data.url
      fileName.value = data.name
      fileInfo.value = data
      console.log(data)
      if (data.share_mode !== shareModeEnum.NOCOPY) {
        canCopy.value = true
      }
    })

    return {
      url,
      pptEditor,
      fileName,
      fileInfo,
      canCopy,
      loading,
      formData,
      handleGoBack,
      handleShowWarning
    }
  }
}
